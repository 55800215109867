


















































































import Vue from "vue";
import pgnList from "@/pgnList";
export default Vue.extend({
  name: "Sidebar",
  props: {
    pgnString: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPgnMenu: true,
      sidebarImg: "https://source.unsplash.com/500x500/?chess",
      pgnPickerRadio: "hosted-pgns",
      pgnFile: new File([], "") as File | null,
      hostedPgnOptions: Object.keys(pgnList)
        .sort()
        .map(k => {
          return { value: pgnList[k], text: k };
        }),
      selectedHostedPgnFile: null
    };
  },
  methods: {
    readPGN(file: File): void {
      if (file != null) {
        const reader = new FileReader();
        reader.onload = evt => {
          if (evt.target != null) {
            this.showPgnMenu = false;
            if (this.pgnFile instanceof File) {
              this.pgnFile.text().then(text => this.$emit("pgnUpdated", text));
            }
          }
        };
        reader.readAsText(file, "string");
      }
    },
    formatFilename(files: File[]): string {
      return files[0].name.slice(0, 22);
    },
    changeSidebarImage(): void {
      this.sidebarImg =
        this.sidebarImg.split("?")[0] + "?chess&" + new Date().getTime();
    },
    clearPgn(): void {
      this.pgnFile = null;
      this.$emit("pgnUpdated", "");
      this.showPgnMenu = false;
      this.$emit("gameSelected", "false");
    },
    async loadHostedPGN(): Promise<void> {
      const response = await fetch("/pgn/" + this.selectedHostedPgnFile);
      const blob: Blob = await response.blob();
      blob.text().then(text => this.$emit("pgnUpdated", text));
      this.showPgnMenu = false;
      this.$emit("gameSelected", "false");
    }
  },
  computed: {
    hostedPgnOptionsDisabled(): boolean {
      return this.hostedPgnOptions.length === 0;
    }
  }
});
