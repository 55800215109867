


















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      siteTitle: "ChamPGN",
      siteSubtitle: "Chess Puzzle Collections",
      shouldShrinkHeader: false
    };
  },
  methods: {
    shrinkHeader(shouldShrinkString: "true" | "false"): void {
      const shouldShrink = shouldShrinkString === "true";
      if (this.shouldShrinkHeader !== shouldShrink) {
        this.shouldShrinkHeader = shouldShrink;
      }
    }
  }
});
