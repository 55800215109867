
















import Vue from "vue";
import PlayArea from "@/components/PlayArea.vue";
import Sidebar from "@/components/Sidebar.vue";

export default Vue.extend({
  name: "Home",
  components: {
    PlayArea,
    Sidebar
  },
  data() {
    return {
      showPgnMenu: true,
      pgnString: ""
    };
  },
  methods: {
    setNewPgn(newPgn: string): void {
      this.pgnString = newPgn;
    },
    formatFilename(files: File[]): string {
      return files[0].name.slice(0, 22);
    },
    shrinkHeader(shouldShrink: "true" | "false") {
      this.$emit("gameSelected", shouldShrink);
    }
  },
  watch: {
    pgnString(newVal: string, oldVal: string) {
      if (newVal !== oldVal) this.$emit("pgnString", newVal);
    }
  }
});
