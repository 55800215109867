





































import Vue from "vue";

export default Vue.extend({
  name: "PromotionModal",
  data() {
    return {
      promotionPiece: "q"
    };
  },
  methods: {
    setPiece(piece: PromotionPiece) {
      this.$emit("promotion", piece);
    }
  }
});
