var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"id":"main-window"}},[_c('div',{class:_vm.windowWidth < 600 && _vm.selectedGame != null
        ? 'col-sm-4 pt-2 compact-selection-list'
        : 'col-sm-4 pt-2',attrs:{"id":"gameSelectionArea"}},[_c('div',{class:_vm.windowWidth < 600 && _vm.selectedGame != null ? 'd-flex flex-row' : 'row'},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.pgn-menu",modifiers:{"pgn-menu":true}}],staticClass:"m-2 order-2",staticStyle:{"min-width":"6.5em"},attrs:{"variant":"success"}},[_vm._v(" Load PGN ")]),_c('b-button',{staticClass:"m-2 order-3",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.show('about')}}},[_vm._v(" About ")]),_c('About',{attrs:{"id":"about"}}),_c('b-form-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.windowWidth >= 600 || _vm.gameListOptions.length > 1),expression:"windowWidth >= 600 || gameListOptions.length > 1"}],class:_vm.windowWidth < 600 && _vm.selectedGame != null
            ? 'order-1 m-2'
            : 'order-4 m-2',attrs:{"options":_vm.gameListOptions,"select-size":_vm.puzzleListRowCount,"disabled":_vm.gameListOptions.length === 0},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"hidden":_vm.gameListOptions.length === 0,"value":null,"disabled":""}},[_vm._v("-- Select a game --")])]},proxy:true}]),model:{value:(_vm.selectedGame),callback:function ($$v) {_vm.selectedGame=$$v},expression:"selectedGame"}})],1)]),_c('div',{class:_vm.windowWidth < 600 ? 'col-sm-8' : 'col-sm-8 pt-2',attrs:{"id":"boardArea"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.windowWidth >= 600 || this.selectedGame),expression:"windowWidth >= 600 || this.selectedGame"}],class:_vm.windowWidth < 600 ? '' : 'my-2',attrs:{"id":"gameHeader"}},[_vm._v(" "+_vm._s(_vm.gameHeaderText.firstline)),_c('br'),_vm._v(" "+_vm._s(_vm.gameHeaderText.secondline)),_c('br')]),_c('ChamBoard',{attrs:{"fen":_vm.currentFen,"side":_vm.boardOrientation,"showThreats":_vm.showThreats},on:{"playerTurnEnd":_vm.changeTurnColor}}),_c('div',{class:_vm.windowWidth < 600
          ? 'playing-side-note mt-2 mb-0'
          : 'playing-side-note mt-3 mb-2'},[_vm._v(" "+_vm._s(this.playerTurn == "white" ? "White to Play" : "Black to Play")+" ")]),_c('div',{staticClass:"row justify-content-center",attrs:{"id":"game-controls-area"}},[_c('div',{staticClass:"col d-flex flex-row justify-content-end"},[_c('b-button',{staticClass:"game-control",attrs:{"id":"resetPositionButton","variant":"info"},on:{"click":_vm.resetPosition}},[_vm._v("Reset Position")])],1),_c('div',{staticClass:"col d-flex flex-column",attrs:{"id":"game-checkbox-div"}},[_c('div',{staticClass:"row d-flex flex-row"},[_c('b-form-checkbox',{staticClass:"game-control",attrs:{"id":"showThreatsCheckbox"},model:{value:(_vm.showThreats),callback:function ($$v) {_vm.showThreats=$$v},expression:"showThreats"}},[_vm._v(" Show Threats ")])],1),_c('div',{staticClass:"row d-flex flex-row"},[_c('b-form-checkbox',{staticClass:"game-control",attrs:{"id":"flipBoardCheckbox"},model:{value:(_vm.isBoardFlipped),callback:function ($$v) {_vm.isBoardFlipped=$$v},expression:"isBoardFlipped"}},[_vm._v(" Flip Board ")])],1),_c('div',{staticClass:"row d-flex flex-row"},[_c('b-form-checkbox',{staticClass:"game-control",attrs:{"id":"showMovesCheckbox","disabled":!this.selectedGame || _vm.noAnnotationsAvailable},on:{"input":_vm.scrollToAnnotations},model:{value:(_vm.showMoves),callback:function ($$v) {_vm.showMoves=$$v},expression:"showMoves"}},[_vm._v(" Show Annotation ")])],1)])]),_c('div',{staticClass:"annotation",attrs:{"id":"annotation-section"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.noAnnotationsAvailable),expression:"noAnnotationsAvailable"}],attrs:{"id":"no-annotations"}},[_vm._v(" No annotation available ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoves),expression:"showMoves"}],attrs:{"id":"game-comments"}},[_vm._v(" "+_vm._s(this.selectedGame && this.selectedGame.comments ? this.selectedGame.comments : "")+" ")]),_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.showMoves && this.selectedGame && this.selectedGame.moves.length > 0
        ),expression:"\n          showMoves && this.selectedGame && this.selectedGame.moves.length > 0\n        "}],staticClass:"annotation text-center",attrs:{"id":"move-list","no-body":""}},[(this.selectedGame && this.selectedGame !== null)?_c('move-annotation',{attrs:{"moves":this.selectedGame.moves,"blackStarts":this.selectedGame.fen.includes(' b '),"windowWidth":this.windowWidth}}):_vm._e(),_vm._v(" "+_vm._s(this.selectedGame && !["*", "?"].includes(this.selectedGame.result) ? "Result: " + this.selectedGame.result : "")+" ")],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }