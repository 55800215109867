




























































































































import Vue from "vue";
import { Nag } from "@/util/nag";

export default Vue.extend({
  name: "move-annotation",
  props: {
    moves: {
      type: Array as () => Move[],
      required: true
    },
    blackStarts: {
      type: Boolean,
      required: false,
      default: false
    },
    labelPrefix: {
      type: String,
      required: false,
      default: "note"
    },
    windowWidth: {
      type: Number,
      required: false,
      default: window.innerWidth
    }
  },
  data() {
    return {
      fields: [
        {
          key: "moveNumber",
          label: "No.",
          sortable: false
        },
        {
          key: "whiteMove",
          label: "W",
          sortable: false,
          tdClass: (value: never, key: never, item: AnnotationTableRow) => {
            if (item.whiteRavs?.length > 0) return "annotation-has-ravs";
            return "";
          },
          tdAttr: (value: never, key: never, item: AnnotationTableRow) => ({
            id: item.whiteLabel
          })
        },
        {
          key: "blackMove",
          label: "B",
          sortable: false,
          tdClass: (value: never, key: never, item: AnnotationTableRow) => {
            if (item.blackRavs?.length > 0) return "annotation-has-ravs";
            return "";
          },
          tdAttr: (value: never, key: never, item: AnnotationTableRow) => ({
            id: item.blackLabel
          })
        }
      ]
    };
  },
  methods: {
    parseNags(nags: (keyof typeof Nag)[]): string[] {
      return nags.map(n => {
        return `(${Nag[n]})`;
      });
    },
    parseComments(move: Move): string {
      if (move.nags) {
        return this.parseNags(move.nags as (keyof typeof Nag)[]).join("\n");
      }
      return move.comments?.map(c => c.text.trim()).join("\n") ?? "";
    }
  },
  computed: {
    movePairs(): MovePair[] {
      const res: MovePair[] = [];
      let i = 0;
      while (i < this.moves.length) {
        if (i === 0 && this.blackStarts) {
          res.push({
            moveNumber: Number(this.moves[i].move_number),
            black: this.moves[i]
          });
          i++;
          continue;
        }

        const move: MovePair = {
          moveNumber: Number(this.moves[i].move_number),
          white: this.moves[i]
        };

        if (i++ < this.moves.length) {
          move.black = this.moves[i];
        }
        res.push(move);
        i++;
      }
      return res;
    },
    Nag() {
      return Nag;
    },
    tableData(): AnnotationTableRow[] {
      return this.movePairs.map(m => ({
        moveNumber: m.moveNumber,
        whiteMove: m.white
          ? m.white.move + "\n" + this.parseComments(m.white)
          : "...",
        whiteRavs: m.white?.ravs ? m.white.ravs : [],
        whiteLabel: this.labelPrefix + "-" + String(m.moveNumber) + "W",
        blackMove: m.black
          ? m.black.move + "\n" + this.parseComments(m.black)
          : "",
        blackRavs: m.black?.ravs ? m.black.ravs : [],
        blackLabel: this.labelPrefix + "-" + String(m.moveNumber) + "B"
      }));
    }
  }
});
