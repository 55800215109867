const pgnList: { [key: string]: string } = {};

pgnList["Endgame Problems"] = "endgames_all_sorted.pgn";
pgnList["More Chess Problems"] = "problems_2015.pgn";
pgnList["Chess Problems"] = "all_problems.pgn";
pgnList["111 Chess Problems"] = "111_problems.pgn";
pgnList["Two-move problems"] = "2_movers.pgn";
pgnList["Chess Problems"] = "problems.pgn";
pgnList["White - A Century of Two-Movers"] = "White___A_Century_of_Two_Movers.pgn";
pgnList["Greif-200 Challenging Chess Puzzles"] = "Greif___200_Challenging_Chess_Puzzles.pgn";
pgnList["Grief-200 Demanding Chess Puzzles"] = "Grief___200_Demanding_Chess_Puzzles.pgn";
pgnList["Hochberg-Solve Chess Problems"] = "Hochberg_Solve_Chess_Problems.pgn";
pgnList["Howard - Spectacular Chess Problems"] = "Howard___Spectacular_Chess_Problems.pgn";
pgnList["Nabokov - Poems and Problems"] = "Nabokov___Poems_and_Problems.pgn";
pgnList["Barnes-200 Chess Problems"] = "Barnes_200_Chess_Problems.pgn";

export default pgnList;

