

































import Vue from "vue";
export default Vue.extend({
  name: "About",
  data() {
    return {
      githubLink: "https://github.com/u-03bb/champgn/",
      modalTitle: "About ChamPGN"
    };
  }
});
